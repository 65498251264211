<template>
  <!-- BEGIN: Content-->
  <div class="chat-application">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Employee Register</a>
        <div>
          <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa-solid fa-file-import"></i>
          </button>
          <button @click="handleCreate" class="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-plus-circle"></i>
          </button>
        </div>
        <div class="d-none d-md-block d-lg-block collapse justify-content-end navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav h4">
            <li class="nav-item me-2">
              <router-link :to="{name:'contact-profile'}"><i class="fa-solid fa-users"></i> List</router-link>
            </li>
            <li @click="handleCreate" class="nav-item">
              <a class="border-r" href="#"><i class="fa fa-plus-circle"></i> Create</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="content-area-wrapper container-xxl p-0">

      <div class="sidebar-left">
        <div class="sidebar">

          <!-- Contact Sidebar area -->
          <div class="sidebar-content" :class="{ 'show': sidebarStatus }">
            <span @click="sidebarActiveInactive" class="sidebar-close-icon">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <!-- Sidebar header start -->
            <div class="chat-fixed-search">
              <div class="d-flex align-items-center w-100">
                <div class="fw-bold h4 sidebar-profile-toggle">
                  Filter
                </div>
                <div class="input-group input-group-merge ms-1 w-100">
                  <span class="input-group-text round"><i data-feather="search" class="text-muted"></i></span>
                  <input v-model="searchTerm" type="text" class="form-control round"
                         id="chat-search"
                         placeholder="Quick Search.." aria-label="Search..."
                         aria-describedby="chat-search"/>
                  <span @click="onOpenContactSearchModal" class="input-group-text round cursor-pointer">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
            <!-- Sidebar header end -->

            <!-- Sidebar Contacts start -->
            <ContactSidebar
                :contacts="contacts"
                :selectedContactId="selectedContactId"
                @selectContact="selectContact"
                @onPageChange="onPageChange"
            />
            <!-- Sidebar Contacts end -->
          </div>
          <!--/ Contact Sidebar area -->
        </div>
      </div>
      <Contents
          :contactInfo="contactInfo"
          :contactDetails="contactDetails"
          @handleEdit="handleEdit"
          @sidebarActiveInactive="sidebarActiveInactive"
      />

      <ContactDropdownFilter 
          v-if="store.state.isModalOpenThree"
          @onSearchContact="onSearchContact"
          :companyRoles="companyRoles"
          :isFilterable="true"
      />
    </div>
  </div>
  <!-- END: Content-->
  <GlobalLoader v-if="loader"/>
</template>

<script setup>
import ContactSidebar from '@/views/page/company/hr-payroll/employee-register/register/ContactSidebar.vue'
import Contents from '@/views/page/company/hr-payroll/employee-register/register/Contents.vue'
import {computed, inject, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import handleRedirectToPage from "@/services/modules/redirectToPage";
import handleCompanyContact from "@/services/modules/companyContact";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import handleRole from "@/services/modules/role";

const router = useRouter()
const route = useRoute()
const store = useStore();
const {fetchCompanyAllContacts, fetchContactDetails} = handleCompanyContact();
const showError = inject('showError');
const showSuccess = inject('showSuccess');

const loader = ref(false)
const sidebarStatus = ref(false)
const contacts = ref([])
const selectedContactId = ref('')
const contactDetails = ref([])
const contactInfo = ref({})
const searchTerm = ref('');
const companyRoles = ref([]);
const contactProfileId = ref(null);
let debounceTimer = null;

const { fetchCompanyDefaultRoles } = handleRole();

let routeQuery = Object.assign({}, route.query)
const companyId = computed(() => route.params.companyId);

const offset = ref(10);
let page = ref(1);

const getCompanyRoles = async () => {
    const res = await fetchCompanyDefaultRoles(`?company_id=${route.params.companyId}`);
    if (res.status) {
        companyRoles.value = res.data
    }
}

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
  contactProfileId.value = contact.id
  getContactsList();
}

const getQuery = () => {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&roles=employee'
  if (route.query.page) query += '&page=' + route.query.page
  if (searchTerm) query += '&q=' + searchTerm.value
  if (contactProfileId.value) query += '&contact_profile_id=' + contactProfileId.value
  return query
}

const handleCreate = () => {
  router.push({name: 'add-employee'})
};

const handleEdit = (contact) => {
  let routeQuery = Object.assign({}, route.query);
  routeQuery.type = contact.contact_type
  delete routeQuery.page
  let hashValue = contact.contact_type === 'person' ? '#basic-info' : '#org-basic-info';
  router.push({name: 'add-employee', query: routeQuery, hash: hashValue});
}

const sidebarActiveInactive = () => {
  sidebarStatus.value = !sidebarStatus.value
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  getContactsList();
}

const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
};

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};

const resetPagination = (data) => {
  store.commit('resetPagination')
};

async function getContactsList() {
  try {
    const res = await fetchCompanyAllContacts(getQuery());
    if (!res.status) {
      contacts.value = [];
    }
    contacts.value = res.data.data;
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

watch(searchTerm, (newValue) => {
  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }

  debounceTimer = setTimeout(() => {
    contactProfileId.value = null
    getContactsList();
  }, 500);
});


const selectContact = (contactId) => {
  const isMobile = window.innerWidth < 768;
  if (isMobile) {
    sidebarActiveInactive()
  }

  selectedContactId.value = contactId
  routeQuery.contactId = selectedContactId.value
  getContactDetails(selectedContactId.value)
  router.push({name: 'employee-register', query: routeQuery})
}

const getContactDetails = async (contactId, type = 'activities') => {
  try {
    const res = await fetchContactDetails(type, contactId, '?company_id=' + companyId.value);
    if (!res.status) {
      return contactDetails.value = []
    }
    contactInfo.value = res.data.contact_profile;
    contactDetails.value = res.data.contact_details;
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

onMounted(async () => {
  await getContactsList()
  if (!route.query.contactId && contacts.value.length) {
    selectContact(contacts.value[0].id)
  }
  if (route.query.contactId) {
    selectedContactId.value = route.query.contactId
    await getContactDetails(selectedContactId.value)
  }

  await getCompanyRoles()
});
</script>


<style scoped lang="scss">
.chat-application {
  .sidebar-content, .chat-fixed-search {
    width: 300px !important;
  }

  li.nav-item {
    a {
      font-size: 22px;
    }
  }

  button.navbar-toggler {
    font-size: 33px;
    color: #8482e5
  }
}
</style>
