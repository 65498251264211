<template>
  <div class="content-right">
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
      </div>
      <div class="content-body">
        <div class="body-content-overlay"></div>
        <!-- Main contact area -->
        <section class="chat-app-window">
          <!-- To load Conversation -->
          <div class="start-chat-area d-none">
            <div class="mb-1 start-chat-icon">
              <i data-feather="message-square"></i>
            </div>
            <h4 class="sidebar-toggle start-chat-text">Select Contact</h4>
          </div>
          <!--/ To load Conversation -->

          <!-- Active Contact -->
          <div class="active-chat">
            <!-- Contact Header -->
            <div class="chat-navbar">
              <header class="chat-header">
                <div class="d-flex align-items-center">
                  <div @click="$emit('sidebarActiveInactive')" class="sidebar-toggle d-block d-lg-none me-1">
                    <i class="fa fa-bars"></i>
                  </div>
                  <div class="avatar avatar-border user-profile-toggle m-0 me-1">
                    <img :src="contactInfo.photo" width="42" class="border-0" :alt="contactInfo.full_name"/>
                  </div>
                  <h6 class="mb-0">
                    {{ contactInfo.full_name }}<br>
                    <small v-for="profile_item in contactInfo.profile_items">
                      {{ profile_item.value }}<br>
                    </small>
                  </h6>
                </div>
                <div @click="$emit('handleEdit', contactInfo)" class="d-flex align-items-center me-md-3 me-lg-3">
                  <h5 role="button" class="btn btn-dark">Profile</h5>
                </div>
              </header>
            </div>
            <!--/ Contact Header -->

            <!-- User Chat messages -->
            <div class="user-chats">
              <div class="chats">
                <ul class="nav nav-tabs">
                  <li v-for="tab in tabs" :key="tab.id" class="nav-item">
                    <a
                        :href="`#${tab.id}`"
                        :class="['nav-link', { active: activeTabId === tab.id }]"
                        @click="setActiveTab(tab)"
                    >
                      {{ tab.title }}
                    </a>
                  </li>
                </ul>
                <component :is="activeTab" :contactDetails="contactDetails" :contactInfo="contactInfo"/>
              </div>
            </div>
            <!-- User Contact messages -->
          </div>
          <!--/ Active Contact -->
        </section>
        <!--/ Main contact area -->
      </div>
    </div>
  </div>
</template>

<script setup>
import Activities from "@/views/page/company/hr-payroll/employee-register/register/tabs/Activities.vue";
import Statements from "@/views/page/company/hr-payroll/employee-register/register/tabs/Statements.vue";
import Attendance from "@/views/page/company/hr-payroll/employee-register/register/tabs/Attendance.vue";
import Projects from "@/views/page/company/hr-payroll/employee-register/register/tabs/Projects.vue";
import Sales from "@/views/page/company/hr-payroll/employee-register/register/tabs/Sales.vue";
import Purchases from "@/views/page/company/hr-payroll/employee-register/register/tabs/Purchases.vue";
import Deals from "@/views/page/company/hr-payroll/employee-register/register/tabs/Deals.vue";
import Meeting from "@/views/page/company/hr-payroll/employee-register/register/tabs/Meeting.vue";
import Note from "@/views/page/company/hr-payroll/employee-register/register/tabs/Note.vue";
import Call from "@/views/page/company/hr-payroll/employee-register/register/tabs/Call.vue";
import Email from "@/views/page/company/hr-payroll/employee-register/register/tabs/Email.vue";
import Message from "@/views/page/company/hr-payroll/employee-register/register/tabs/Message.vue";
import Attachment from "@/components/molecule/company/hospital/patient/register/tabs/Attachment.vue";
import More from "@/views/page/company/hr-payroll/employee-register/register/tabs/More.vue";
import {ref} from "vue";

const activeTab = ref(Statements)
const activeTabId = ref('statements')
const props = defineProps({
  contactInfo: {
    type: Object,
    default: {
      full_name: '',
      profile_items: [],
      contact: {},
    }
  },
  contactDetails: {
    type: Array,
    default: []
  }
})

const tabs = [
  // {id: 'activities', title: 'Activities', component: Activities},
  {id: 'attendance', title: 'Attendance', component: Attendance},
  {id: 'statements', title: 'Statements', component: Statements},
  {id: 'sales', title: 'Sales', component: Sales},
  {id: 'purchases', title: 'Purchases', component: Purchases},
  {id: 'projects', title: 'Projects', component: Projects},
  {id: 'deals', title: 'Deals', component: Deals},
  {id: 'call', title: 'Call', component: Call},
  {id: 'email', title: 'Email', component: Email},
  {id: 'message', title: 'Message', component: Message},
  {id: 'meeting', title: 'Meeting', component: Meeting},
  {id: 'note', title: 'Note', component: Note},
  {id: 'attachment', title: 'Attachment', component: Attachment},
  // {id: 'more', title: 'More', component: More}
]

const setActiveTab = tab => {
  activeTab.value = tab.component;
  activeTabId.value = tab.id;
};


</script>

<style scoped>
.user-chats {
  background-image: none !important;
  overflow-y: scroll;
  height: calc(100% - 65px);
  overflow-x: hidden;
}
</style>
