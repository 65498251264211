<template>
  <Attendance :contactInfo="contactInfo"/>
</template>
<script setup>
import Attendance from '@/views/page/company/hr-payroll/attendance-report/List.vue'
import {useRoute, useRouter} from "vue-router";

const props = defineProps({
  contactInfo: {
    type: Object,
    default: {
      full_name: '',
      profile_items: [],
      contact: {},
    }
  },
})


console.log("pr",props)

const route = useRoute();
const router = useRouter();
const appendQueryParams = () => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2).toISOString().split('T')[0];
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1).toISOString().split('T')[0];

  const newQuery = {
    ...route.query,
    start: firstDayOfMonth,
    end: lastDayOfMonth,
    page: '1'
  };

  router.push({query: newQuery});
};
appendQueryParams();
</script>

<style scoped>

</style>
