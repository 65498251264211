<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                title="Attendance"
            />
        </div>
        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickToGo"
                class="tds-vds-query-setter"
            >
              <div v-if="!contactInfo" class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label for="colFormLabel" class="col-form-label">Employee</label>
                <v-select
                    placeholder="Select Employee"
                    v-model="contactProfileId"
                    :options="contacts"
                    label="name"
                    :reduce="name => name.id"
                />
              </div>
            </DateQuerySetter>
        </div>

        <div class="col-12">
          <div class="d-flex justify-content-end px-2">
            <button
                @click="exportTable"
                class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
            >
              Export
            </button>
          </div>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable :tableItems="tableItems" />
        </div>

        <Loader v-if="loading"/>

    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import ListTable from '@/components/molecule/company/hrm-payroll/attendance-report/ListTable.vue';
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import { computed } from 'vue';
import handleContact from '@/services/modules/contact'

const props = defineProps({
  contactInfo: {
    type: Object
  }
})

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const contactProfileId = ref('');
let contacts = ref([])
const tableItems = ref([]);
const params = ref({
    offset: 30,
});

const token = computed(() => localStorage.getItem('token'));

const {
  fetchAttendanceList,
} = handleHrPayroll()
const { fetchContactProfiles } = handleContact()

const onClickToGo = () => {
   getAttendanceList();
}

const onPageChange = async (page) => {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getAttendanceList();
}

const getAttendanceList = async () => {
    const query = getQuery();
    loading.value = true
    tableItems.value = []

    await fetchAttendanceList(query).then(res => {
        if (!res.status){
            showError(res.message)
            return
        }

        tableItems.value = res.data;
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page;
    if (route.query.start) query += '&start_date=' + route.query.start;
    if (route.query.end) query += '&end_date=' + route.query.end;
    if (contactProfileId.value) query += '&contact_profile_id=' + contactProfileId.value
    if (props.contactInfo) query += '&contact_profile_id=' + props.contactInfo.id
    query += '&offset=' + params.value.offset
    query += '&is_contact=true'
    return query;
}

const exportTable = () => {
  let query = getQuery();
  let url = `${process.env.VUE_APP_BASE_URL}/api/hr-payroll/attendance${query}&_token=${token.value}&is_export=1`;
  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.click();
};

onMounted(() => {
    const contactQuery =  `?company_id=${route.params.companyId}&type=employee`
    const contactRes   = fetchContactProfiles(contactQuery)

    Promise.all([
        contactRes.then(res => {
          if (res.data) contacts.value = res.data
        }),
    ])
})
</script>
